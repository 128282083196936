<template>
	<div>
		<v-row class="mot-survey-form-large">
			<v-col>
				<p class="text-subtitle-1 text-left">あなたについて、以下のことを教えてください。</p>
			</v-col>
		</v-row>
		<v-row class="mot-survey-form-large">
			<v-col>
				<p class="text-subtitle-1 text-left">性別<span class="star">*</span></p>
			</v-col>
		</v-row>
		<v-row class="mot-survey-form">
			<v-col>
				<v-select
					v-model="stateQ7"
					label="性別"
					:items="genders"
					item-text="text"
					item-value="value"
					:rules="[rules.required]"
				></v-select>
			</v-col>
		</v-row>
		<v-row class="mot-survey-form-large">
			<v-col>
				<p class="text-subtitle-1 text-left">年齢<span class="star">*</span></p>
			</v-col>
		</v-row>
		<v-row class="mot-survey-form">
			<v-col>
				<v-select
					v-model="stateQ8"
					label="年齢"
					:items="ages"
					item-text="text"
					item-value="value"
					:rules="[rules.required]"
				></v-select>
			</v-col>
		</v-row>
		<v-row class="mot-survey-form-large">
			<v-col>
				<p class="text-subtitle-1 text-left">司書資格を取得した大学・短期大学<span class="star">*</span></p>
			</v-col>
		</v-row>
		<v-row class="mot-survey-form-small">
			<v-col>
				<v-text-field
					v-model="stateQ9"
					label=""
					persistent-hint
					:rules="[rules.max100chars, rules.required]"
					placeholder="例：〇〇大学"
					counter
					maxlength="100"
					rows="3"
				></v-text-field>
			</v-col>
		</v-row>
	</div>
</template>

<style scoped>
.mot-survey-form-large {
	margin-top: 0em;
}

.mot-survey-form-small {
	margin-top: -3.3em;
}

.mot-survey-form-margin-bottom {
	margin-bottom: 0em;
}

.mot-survey-form {
	margin-top: -2.5em;
}

.note {
	font-size: 0.9em;
	color: #777;
}

.star {
	color: red;
	font-size: 1.2em;
	margin-left: 0.2em;
}

</style>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'UserSurvey',
	data () {
		return {
			form: {
				gender: null,
				age:null,
				university: '',
			},
			rules: {
				required: value => !!value || '必須',
				max100chars: value => value.length <= 100 || '最大100字',
				max400chars: value => value.length <= 400 || '最大400字',
			},
			genders: [
				{ text: '男', value: 1 },
				{ text: '女', value: 2 },
				{ text: 'その他', value: 3 },
				{ text: '回答しない', value: 4 },
			],
			ages: [
				{ text: '20代', value: 1 },
				{ text: '30代', value: 2 },
				{ text: '40代', value: 3 },
				{ text: '50代', value: 4 },
				{ text: '60代以降', value: 5 },
				{ text: '回答しない', value: 6 },
			],
		}
	},
	computed: {
		...mapGetters({
			Q7: 'stateQ7',
			Q8: 'stateQ8',
			Q9: 'stateQ9',
		}),
		stateQ7: {
			get () {
				return this.Q7
			},
			set (value) {
				this.setQ7(value)
			}
		},
		stateQ8: {
			get () {
				return this.Q8
			},
			set (value) {
				this.setQ8(value)
			}
		},
		stateQ9: {
			get () {
				return this.Q9
			},
			set (value) {
				this.setQ9(value)
			}
		}
	},
	methods: {
		...mapActions([
			'setQ7',
			'setQ8',
			'setQ9',
		])
	}
}
</script>